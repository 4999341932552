<template>
  <div class="view-payStatus">
    <div class="loading">
      <img src="@/assets/imgs/account/payLoading.png" alt="" />
      <div class="tag">支付处理中，请稍等</div>
    </div>
    <div class="tips">
      我们已向第三方发起交易请求，如果交易失败，我们会把交易金额原数退还到您的账户中
    </div>
    <van-dialog
      v-model="show"
      class="view-dialog"
      confirmButtonText="知道了"
      @confirm="confirm"
    >
      <img class="dialog-icon" src="@/assets/imgs/account/success.png" alt="" />
      <div class="dialog-title">支付成功</div>
      <div class="dialog-tag">请您查看账户可用份数</div>
    </van-dialog>
  </div>
</template>

<script>
  import accountApi from '@/api/account'

  export default {
    data() {
      return {
        show: false,
        timer: null,
      }
    },
    created() {
      this.tradeId = this.$route.query.tradeId
      this.getTradeStatus()
    },
    destroyed() {
      clearTimeout(this.timer)
    },
    methods: {
      getTradeStatus() {
        accountApi
          .getTradeStatus({
            tradeId: this.tradeId,
          })
          .then(res => {
            // 交易状态：0-初始状态；1-已申请未确认；2-已确认(已报盘未回盘)；3-支付成功；4支付失败；5订单作废(第三方过期或关闭)6用户放弃支付
            if (res === 3) {
              this.show = true
            } else if (res === 4 || res === 5 || res === 6) {
              this.$toast('支付失败')
              this.$router.go(-2)
            } else {
              this.timer = setTimeout(() => {
                this.getTradeStatus()
              }, 1000)
            }
          })
      },
      confirm() {
        if (this.$route.query.path && this.$route.query.key) {
          this.$router.push({
            path: this.$route.query.path,
            query: {
              key: this.$route.query.key,
            },
          })
          return
        }
        this.$router.push({
          path: '/my',
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-payStatus {
    padding: 60px 38px 0;
    .loading {
      text-align: center;
      margin-bottom: 27px;
      img {
        width: 60px;
        margin-bottom: 28px;
      }
      .tag {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #202124;
        line-height: 20px;
      }
    }
    .tips {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #677283;
      line-height: 24px;
    }
  }
  .view-dialog {
    /deep/ .van-dialog__content {
      text-align: center;
      padding: 24px;
      .dialog-icon {
        width: 60px;
        margin-bottom: 18px;
      }
      .dialog-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #202124;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .dialog-tag {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #677283;
        line-height: 24px;
      }
    }
  }
</style>
